<script setup async>
import {PurchaseService, UserService, DirectoryService} from "@/common/api.service";
import {useRoute, useRouter} from 'vue-router'
import {computed, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useToast} from "vue-toastification";
import moment from "moment";
import shared from "@/common/shared";
import ValidateInput from "@/components/validate/input.vue";

const route = useRoute()
const router = useRouter()
const store = useStore()
const toast = useToast()
const item = ref({
  EXPENSES: [],
  EXPENSES_FHD: [],
  AVAILABLE_TRANSITIONS: [],
  BP_RIGHTS: {
    BLOCKS: []
  }
})
const statusHistory = ref([])
const chiefs = ref([])
const writers = ref([])
const readers = ref([])
const editExpense = ref({
  UF_YEAR: Number,
  UF_SUM: Number.NaN,
  isNew: true
})
const editExpenseFHD = ref({
  UF_PLAN_EXPENSE_ID: null,
  UF_YEAR: Number,
  UF_SUM: Number.NaN,
  UF_COST_CENTER_ID: Number,
  UF_FIN_SOURCE_ID: Number,
  UF_EXPENSE_TYPE_ID: Number,
  UF_CEC_ID: Number,
  UF_ETC_ID: Number,
  isNew: true
})
const editStatus = ref({
  TRANSITION: "",
  COMMENT: "",
  UF_TEXT_LABEL_CONTROL: ""
})
const deleteExpenseKey = ref(-1)
const revoke = ref({
  COMMENT: ""
})
const isLoading = ref(false)
const okpd = ref([])
const okved = ref([])

await store.dispatch("fetchDirectory", {
  'ei': 'simple',
  'purchase_plan_methods': 'simple',
  'okato': 'simple',
  'cost_centers': 'simple',
  'fin_sources': 'simple',
  'expenses_types': 'simple',
  'cec': 'simple',
  'list_reasons_change': 'simple',
})

const itemLoadResult = reactive({
  ok: undefined,
  message: '',
})

async function loadItem(id) {
  await PurchaseService.item(id)
      .then(({data}) => {
        if (data.OKPD) {
          okpd.value.push(data.OKPD)
        }
        if (data.OKVED) {
          okved.value.push(data.OKVED)
        }
        if (data.PLANNED_DOGOVOR) {
          data.pddm = data.PLANNED_DOGOVOR.substring(0, 2)
          data.pddy = data.PLANNED_DOGOVOR.substring(3, 7)
        }
        data.pdm = data.PLACEMENT_DATE.substring(0, 2)
        data.pdy = data.PLACEMENT_DATE.substring(3, 7)
        data.edm = data.EXECUTION_DATE.substring(0, 2)
        data.edy = data.EXECUTION_DATE.substring(3, 7)
        if (!data.UF_COST) {
          data.UF_COST = 0
        }

        if (data.PURCHASE_REQUEST && data.PURCHASE_REQUEST.ID > 0 && data.PURCHASE_REQUEST.UF_NOTICE_NUMBER != ''){
           data.BP_RIGHTS.UF_IS_MAKE_CHANGE_BT_REQUEST_SHOW = false;
        }else{
          data.BP_RIGHTS.UF_IS_MAKE_CHANGE_BT_REQUEST_SHOW = true;
        }

        item.value = data
        console.log(data);
        itemLoadResult.ok = true
      }).catch((e) => {
        itemLoadResult.ok = false
        itemLoadResult.message = e.response.data.message
      })
}

await loadItem(route.params.id)

await UserService.list({
  GROUPS_IDS: [10]
})
    .then(({data}) => {
      chiefs.value = data
    }).catch(() => {

    })

await UserService.list({
  GROUPS_IDS: [9]
})
    .then(({data}) => {
      writers.value = data
    }).catch(() => {

    })

await UserService.list({})
    .then(({data}) => {
      readers.value = data
    }).catch(() => {

    })

const readersList = computed(() => {
  let r = []
  readers.value.forEach(function (f) {
    if (store.getters.currentUser.ID !== f.ID) {
      r.push({value: f.ID, label: f.FULL_NAME})
    } else {
      r.push({value: f.ID, label: f.FULL_NAME, disabled: true})
    }
  })
  return r
})
const writerList = computed(() => {
  let r = []
  writers.value.forEach(function (f) {
    if (store.getters.currentUser.ID !== f.ID) {
      r.push({value: f.ID, label: f.FULL_NAME})
    } else {
      r.push({value: f.ID, label: f.FULL_NAME, disabled: true})
    }
  })
  return r
})
const eiList = computed(() => {
  let r = []
  store.state.directory.simple.ei.forEach(function (f) {
    r.push({value: f.ID, label: `${f.UF_CODE} - ${f.UF_NAME}`})
  })

  return r
})
const okpdList = computed(() => {
  let r = []
  okpd.value.forEach(function (f) {
    r.push({value: f.ID, label: `${f.UF_CODE} - ${f.UF_NAME}`})
  })

  return r
})
const reasonList = computed(() => {
  let r = []
  store.state.directory.simple.list_reasons_change.forEach(function (f) {
    r.push({value: f.ID, label: f.UF_NAME})
  })

  return r
})
const okvedList = computed(() => {
  let r = []
  okved.value.forEach(function (f) {
    r.push({value: f.ID, label: `${f.UF_CODE} - ${f.UF_NAME}`})
  })

  return r
})
const readersChecked = computed(() => {
  let r = []
  item.value.USER_RIGHTS.READ.forEach(function (f) {
    r.push(readers.value.find(i => i.ID === f).FULL_NAME)
  })
  return r.join(', ')
})
const writersChecked = computed(() => {
  let r = []
  item.value.USER_RIGHTS.EDIT.forEach(function (f) {
    r.push(writers.value.find(i => i.ID === f).FULL_NAME)
  })
  return r.join(', ')
})
const makesChangedChecked = computed(() => {
  let r = []
  item.value.REASONS_MAKING_CHANGES_IDS.forEach(function (f) {
    r.push(store.state.directory.simple.list_reasons_change.find(i => i.ID === f).UF_NAME)
  })
  return r.join('; ')
})
const expensesSum = computed(() => {
  let sum = 0
  item.value.EXPENSES.forEach(function (v) {
    sum += parseFloat(v.UF_SUM)
  })
  return sum.toFixed(2)
})
const changeStatuses = computed(() => {
  return item.value.AVAILABLE_TRANSITIONS
})
const expenseYears = computed(() => {
  let r = []
  let sy = moment().toDate().getFullYear()
  for (let i = 0; i < 7; i++) {
    let ar = sy + i
    if (!editExpense.value.isNew || item.value.EXPENSES.findIndex(el => el.UF_YEAR === ar) === -1) {
      r.push(ar)
    }
  }
  return r
})

watch(
    () => route.params.id,
    async newId => {
      await loadItem(newId)
    }
)

async function deleteDocument(id) {
  await PurchaseService.deleteDocument(route.params.id, id).then(({data}) => {
    item.value.DOCUMENTS = data.DOCUMENTS
  }).catch(() => {

  })
}

async function onAddFile(e) {
  let files = e.target.files;
  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      item.value.DOCUMENTS.push({
        file_name: file.name,
        type: file.type,
        size: Math.round(file.size / 1000) + " kB",
        value: reader.result,
        file: file
      })
    }
    let formData = new FormData();
    formData.append("file", file);
    await PurchaseService.addDocument(route.params.id, formData).then(({data}) => {
      item.value.DOCUMENTS = data.DOCUMENTS
    }).catch(() => {

    })
  }
}

async function showStatusHistory() {
  await PurchaseService.statusHistory(route.params.id).then(({data}) => {
    statusHistory.value = data
    shared.showModal('#status-history')
  }).catch(() => {

  })
}

async function showExpenseEdit(data) {
  if (data) {
    editExpense.value = data
  } else {
    editExpense.value = {
      isNew: true
    }
  }
  shared.showModal('#edit-expense')
}

async function showExpenseFHDEdit(data) {
  if (data) {
    editExpenseFHD.value = data
  } else {
    editExpenseFHD.value = {
      isNew: true
    }
  }
  shared.showModal('#edit-expense-fhd')
}

async function showDeleteFHDExpenseModal(data) {
  deleteExpenseKey.value = data
  shared.showModal('#expense-fhd-delete')
}

async function deleteFHDExpense() {
  item.value.EXPENSES_FHD.splice(deleteExpenseKey.value, 1)
  shared.closeModal('#expense-fhd-delete')
}

async function showDeleteExpenseModal(data) {
  deleteExpenseKey.value = data
  shared.showModal('#expense-delete')
}

async function deleteExpense() {
  item.value.EXPENSES.splice(deleteExpenseKey.value, 1)
  shared.closeModal('#expense-delete')
}

async function changeExpense() {
  if (!editExpense.value.UF_YEAR) {
    toast.error("Укажите год", {
      timeout: 3000
    });
    return
  }
  if (isNaN(editExpense.value.UF_SUM)) {
    toast.error("Укажите сумму", {
      timeout: 3000
    });
    return
  }
  if (!editExpense.value.isNew) {
    let i = item.value.EXPENSES.find(item => item.ID === editExpense.value.ID)
    i.UF_YEAR = editExpense.value.UF_YEAR
    i.UF_SUM = editExpense.value.UF_SUM
  } else {
    item.value.EXPENSES.push({
      UF_YEAR: editExpense.value.UF_YEAR,
      UF_SUM: editExpense.value.UF_SUM,
    })
  }
  shared.closeModal('#edit-expense')
}

async function changeExpenseFHD() {
  if (!editExpenseFHD.value.UF_PLAN_EXPENSE_ID) {
    toast.error("Укажите год", {
      timeout: 3000
    });
    return
  }
  if (!editExpenseFHD.value.UF_SUM) {
    toast.error("Укажите сумму", {
      timeout: 3000
    });
    return
  }
  if (!editExpenseFHD.value.UF_COST_CENTER_ID) {
    toast.error("Укажите центр затрат", {
      timeout: 3000
    });
    return
  }
  if (!editExpenseFHD.value.UF_FIN_SOURCE_ID) {
    toast.error("Укажите источник финансирования", {
      timeout: 3000
    });
    return
  }
  if (!editExpenseFHD.value.UF_EXPENSE_TYPE_ID) {
    toast.error("Укажите вид расходов", {
      timeout: 3000
    });
    return
  }
  if (!editExpenseFHD.value.UF_CEC_ID) {
    toast.error("Укажите КЭК", {
      timeout: 3000
    });
    return
  }
  if (!editExpenseFHD.value.UF_ETC_ID) {
    toast.error("Укажите КВР", {
      timeout: 3000
    });
    return
  }
  if (!editExpenseFHD.value.isNew) {
    let i = item.value.EXPENSES_FHD.find(item => item.ID === editExpenseFHD.value.ID)
    i.UF_YEAR = editExpenseFHD.value.UF_PLAN_EXPENSE_ID ? item.value.EXPENSES.find(item => item.ID === editExpenseFHD.value.UF_PLAN_EXPENSE_ID).UF_YEAR : null
    i.UF_PLAN_EXPENSE_ID = editExpenseFHD.value.UF_PLAN_EXPENSE_ID
    i.UF_SUM = editExpenseFHD.value.UF_SUM
    i.UF_COST_CENTER_ID = editExpenseFHD.value.UF_COST_CENTER_ID
    i.UF_FIN_SOURCE_ID = editExpenseFHD.value.UF_FIN_SOURCE_ID
    i.UF_EXPENSE_TYPE_ID = editExpenseFHD.value.UF_EXPENSE_TYPE_ID
    i.UF_CEC_ID = editExpenseFHD.value.UF_CEC_ID
    i.UF_ETC_ID = editExpenseFHD.value.UF_ETC_ID
  } else {
    item.value.EXPENSES_FHD.push({
      UF_PLAN_EXPENSE_ID: editExpenseFHD.value.UF_PLAN_EXPENSE_ID,
      UF_YEAR: editExpenseFHD.value.UF_PLAN_EXPENSE_ID ? item.value.EXPENSES.find(item => item.ID === editExpenseFHD.value.UF_PLAN_EXPENSE_ID).UF_YEAR : null,
      UF_SUM: editExpenseFHD.value.UF_SUM,
      UF_COST_CENTER_ID: editExpenseFHD.value.UF_COST_CENTER_ID,
      UF_FIN_SOURCE_ID: editExpenseFHD.value.UF_FIN_SOURCE_ID,
      UF_EXPENSE_TYPE_ID: editExpenseFHD.value.UF_EXPENSE_TYPE_ID,
      UF_CEC_ID: editExpenseFHD.value.UF_CEC_ID,
      UF_ETC_ID: editExpenseFHD.value.UF_ETC_ID,
    })
  }
  shared.closeModal('#edit-expense-fhd')
}

async function changeStatus(item) {
  if (item.UF_IS_SET_COMMENT_CHANGE_STATUS) {
    editStatus.value = {
      COMMENT: "",
      TRANSITION: item.NAME,
      TYPE: item.TYPE,
      UF_TEXT_LABEL_CONTROL: item.UF_TEXT_LABEL_CONTROL
    }
    shared.showModal('#edit-status')
  } else {
    await saveStatus({
      COMMENT: "",
      TRANSITION: item.NAME,
      TYPE: item.TYPE
    })
  }
}

async function saveItem(hideToasts) {
  return PurchaseService.update(item.value)
      .then(async ({data}) => {
        data.pddm = data.PLANNED_DOGOVOR.substring(0, 2)
        data.pddy = data.PLANNED_DOGOVOR.substring(3, 7)
        data.pdm = data.PLACEMENT_DATE.substring(0, 2)
        data.pdy = data.PLACEMENT_DATE.substring(3, 7)
        data.edm = data.EXECUTION_DATE.substring(0, 2)
        data.edy = data.EXECUTION_DATE.substring(3, 7)
        item.value = data
        if (!hideToasts) {
          toast.success("Данные обновлены", {
            timeout: 2000
          });
        }
        return data
      }).catch(async ({response}) => {
        if (!hideToasts) {
          toast.error(response.data.message, {
            timeout: 5000
          })
        }

        return response.data
      })
}

async function saveStatus(data) {
  let canSave = true
  if (item.value.BP_RIGHTS.UF_IS_EDIT) {
    canSave = false
    await saveItem(true).then(async (response) => {
      if (response.message && data.TYPE === 'forward') {
        toast.error(response.message, {
          timeout: 5000
        })
        return
      }
      canSave = true
    })
  }
  if (canSave) {
    await PurchaseService.statusChange(route.params.id, data)
        .then(async () => {
          if (data.message) {
            toast.error(data.message, {
              timeout: 5000
            })
            return
          }
          shared.closeModal('#edit-status')
          toast.success("Статус изменен", {
            timeout: 2000
          })
          await router.push({name: "ListPurchase"});
        }).catch(({response}) => {
          toast.error(response.data.message, {
            timeout: 5000
          })
        })
  }
}

function revokeItem(i) {
  if (!i) {
    revoke.value.COMMENT = ''
    shared.showModal('#revoke-item')
    return
  }
  shared.closeModal('#revoke-item')
  PurchaseService.revoke(route.params.id, {
    COMMENT: revoke.value.COMMENT,
  })
      .then(() => {
        toast.success("Закупка успешно отозвана", {
          timeout: 2000
        })
        router.push({name: "ListPurchase"});
      })
      .catch(async ({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

function copyItem(i) {
  if (!i) {
    shared.showModal('#copy-item')
    return
  }
  shared.closeModal('#copy-item')
  PurchaseService.copy(route.params.id)
      .then(({data}) => {
        toast.success("Новая карточка успешно создана", {
          timeout: 2000
        })
        router.push({name: "CardPurchase", params: {id: data.ID}});
      })
      .catch(async ({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

function makeChange(i) {
  if (!i) {
    shared.showModal('#make-change-item')
    return
  }
  shared.closeModal('#make-change-item')
  PurchaseService.makeChange(route.params.id)
      .then(({data}) => {
        toast.success("Новая карточка для внесения изменения в план создана", {
          timeout: 2000
        })
        router.push({name: "CardPurchase", params: {id: data.ID}});
      })
      .catch(async ({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

function cancelPlan(i) {
  if (!i) {
    shared.showModal('#cancel-request')
    return
  }
  shared.closeModal('#cancel-request')
  PurchaseService.cancel(route.params.id)
      .then(() => {
        toast.success("Заявка аннулирована", {
          timeout: 2000
        })

        router.push({name: "ListPurchase"});
      })
      .catch(async ({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

function excludePlan(i) {
  if (!i) {
    shared.showModal('#make-change-item')
    return
  }
  shared.closeModal('#make-change-item')
  PurchaseService.excludePlan(route.params.id)
      .then(({data}) => {
        toast.success("Карточка исключена из плана", {
          timeout: 2000
        })
        router.push({name: "CardPurchase", params: {id: data.ID}});
      })
      .catch(async ({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

function setPlannedDogovorDate() {
  if (!item.value.pddm || !item.value.pddy) {
    item.value.PLANNED_DOGOVOR = ""
  } else {
    item.value.PLANNED_DOGOVOR = `${item.value.pddm}.${item.value.pddy}`
  }
}

function setPlacementDate() {
  if (!item.value.pdm || !item.value.pdy) {
    item.value.PLACEMENT_DATE = ""
  } else {
    item.value.PLACEMENT_DATE = `${item.value.pdm}.${item.value.pdy}`
  }
}

function setExecutionDate() {
  if (!item.value.edm || !item.value.edy) {
    item.value.EXECUTION_DATE = ""
  } else {
    item.value.EXECUTION_DATE = `${item.value.edm}.${item.value.edy}`
  }
}

function checkMSP() {
  DirectoryService.search({
        name: 'okpd',
        filters: {ID: item.value.UF_OKPD_ID}
      }
  ).then(({data}) => {
    if (data.find(f => f.ID === item.value.UF_OKPD_ID).UF_IS_SET_MSP) {
      item.value.UF_IS_MSP = true
    }
  })
}

function directorySearch(directory, query, dr) {
  isLoading.value = true
  DirectoryService.search({name: directory, q: query}).then(({data}) => {
    dr.value = data
    isLoading.value = false
  })
}

function okpdSearch(query) {
  directorySearch('okpd', query, okpd)
}

function okvedSearch(query) {
  directorySearch('okved', query, okved)
}

</script>
<template>
  <div class="main-wraper" id="js-main-wraper" style="padding-top: 80px">
    <MainHeader></MainHeader>
    <template v-if="itemLoadResult.ok">
      <section class="content-wrapper" id="js-content-wrapper">
        <div class="container-fluid">
          <section class="page-title-container">
            <div class="page-title-container__col">
              <div class="page-breadcrumbs">
                              <span class="stl-fw-600">
                                   <router-link
                                       :to="{name: 'ListPurchase', query: { _r: route.query._r }}">План закупок</router-link></span>
              </div>
            </div>
            <div class="page-title-container__col page-title-container__controll">
            </div>
          </section>
          <!-- page-title-container -->

          <!--  Блок со статусами -->
          <section class="page-container page-container--status"
                   v-if="item.STATUS_BAR && item.STATUS_BAR.items.length > 0">
            <div class="fpc-application-status box-stl">
              <div class="fpc-block-title holder-title">Статус закупки № {{ item.ID }}
                <span v-if="item.STATUS_BAR.items.length > 1">- {{ item.STATUS.UF_NAME }}</span>
              </div>
              <div class="fpc-application-status__body">
                <div v-if="item.STATUS_BAR.items.length === 1" class="fpc-application-status__items">
                  <div class="fpc-application-status__item fpc-application-status__item--full"
                       :class="{
                    'fpc-application-status--rejected': item.STATUS_BAR.items[0].color == 'light-red' || item.STATUS_BAR.items[0].color == 'red',
                    'fpc-application-status--grey': item.STATUS.UF_CODE==='archive',
                  }"
                  >{{ item.STATUS.UF_NAME }}
                  </div>
                </div>
                <div v-else class="fpc-application-status__items">
                  <div :key="status.ID" v-for="status in item.STATUS_BAR.items"
                       :class="'fpc-application-status__item fpc-application-status--'+status.color">
                    <div class="fpc-help-box">
                      <div class="fpc-help-box__top"></div>
                      <div class="fpc-help-box__body">{{ status.status.UF_NAME }}</div>
                    </div>
                  </div>
                </div>
                <!-- fpc-application-status__items -->
              </div>
              <div v-if="item.STATUS_BAR.message && item.STATUS_BAR.message_prefix"
                   class="fpc-block-title fpc-block-subtitle holder-title">
                <span class="stl-fw-bold">{{ item.STATUS_BAR.message_prefix }}</span> {{ item.STATUS_BAR.message }}
              </div>
              <div v-else-if="item.STATUS_BAR.message" class="fpc-block-title fpc-block-subtitle holder-title">
                <span class="stl-fw-bold">причина отказа</span> {{ item.STATUS_BAR.message }}
              </div>
            </div>
          </section>
          <!--  Блок со статусами -->

          <section class="page-container">
            <div class="row card-row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-col">
                <div class="box-stl card-holder"
                     v-if="item.BP_RIGHTS.BLOCKS.general_information.UF_IS_VIEW && item.BP_RIGHTS.BLOCKS.general_information.UF_IS_EDIT">
                  <div class="holder-title card-title df-ac df-jsb">
                    <div class="card-title--box df-fc">
                      Общая информация
                      <span class="holder-title__attribute" v-if="item.UF_IS_CANCELLATION">Аннулирование
                        <br/>
                      </span>
                      <span class="holder-title__attribute"
                            v-else-if="item.SOURCE && item.SOURCE.ID > 0 && item.UF_IS_EXCLUDE_PLAN === 1">Исключение закупки из плана
                        <br/>
                      </span>
                      <span class="holder-title__attribute"
                            v-else-if="item.SOURCE && item.SOURCE.ID > 0 && item.UF_IS_EXCLUDE_PLAN === 0&& item.SOURCE.UF_STATUS_ID !== 6">Изменение закупки в плане</span>
                      <span class="holder-title__attribute"
                            v-else-if="item.SOURCE && item.SOURCE.ID > 0 && item.UF_IS_EXCLUDE_PLAN === 0&& item.SOURCE.UF_STATUS_ID === 6">
                            Внесены изменения
                      </span>

                      <span class="holder-title__attribute"
                            v-if="item.PURCHASE_REQUEST && item.PURCHASE_REQUEST.ID > 0 && item.PURCHASE_REQUEST.UF_NOTICE_NUMBER != '' && item.PURCHASE_REQUEST.UF_NOTICE_NUMBER != null">
                             Извещение № {{ item.PURCHASE_REQUEST.UF_NOTICE_NUMBER }}
                            <template v-if="item.PURCHASE_REQUEST.UF_NOTICE_DATE">
                              от {{ moment(item.PURCHASE_REQUEST.UF_NOTICE_DATE).format("DD.MM.YYYY")}}
                            </template>
                      </span>

                    </div>
                    <span class="holder-title__attribute"
                          v-if="item.SOURCE && item.SOURCE.ID > 0 || item.CHILDREN.length">
                      Версия №{{ item.SOURCE_DEPTH }}<br/>
                       <router-link
                           v-if="item.CHILDREN.length"
                           :to="{name: 'CardPurchase', params: { id: item.CHILDREN[0].ID }}">Новая версия ({{
                           item.CHILDREN[0].ID
                         }}) <br/></router-link>
                      <router-link
                          v-if="item.SOURCE && item.SOURCE.ID > 0"
                          :to="{name: 'CardPurchase', params: { id: item.SOURCE.ID }}">Карточка - источник ({{
                          item.SOURCE.ID
                        }})</router-link>
                    </span>
                  </div>
                  <div class="card-holder__body">
                    <ValidateInput v-if="item.PLAN"
                        :name="'План'"
                        number
                        :required="true"
                        :disabled="true"
                        v-model="item.PLAN.UF_YEAR"></ValidateInput>
                    <ValidateInput
                        :name="'Предмет закупки'"
                        :required="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_SUBJECT.required"
                        :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_SUBJECT.available"
                        textarea
                        v-model="item.UF_SUBJECT"></ValidateInput>
                    <ValidateInput
                        :name="'Цена (НМЦД - Начальная (максимальная) цена договора), руб.'"
                        :required="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_COST.required"
                        :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_COST.available"
                        :float-format="2"
                        v-model="item.UF_COST">
                      <template v-slot:field="{validationFailed, change, disabled, rounded,floatFormat}">
                        <NumberInput
                            :input-class="{'c-input': true, 'unvalidate': validationFailed}"
                            @change="change"
                            :disabled="disabled"
                            :rounded="rounded"
                            :float-format="floatFormat"
                            v-model="item.UF_COST"
                        />
                        <div style="padding-top: 10px">
                          <label>
                            <input type="checkbox"
                                   v-model="item.UF_IS_PLANNED_THIRD_OR_LAST"
                                   :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_IS_PLANNED_THIRD_OR_LAST.available"
                            >
                            Закупка запланирована на третий или последующие годы реализации плана закупки
                          </label>
                        </div>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Количество'"
                        number
                        :required="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_QUANTITY.required"
                        :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_QUANTITY.available"
                        :float-format="store.getters.simpleItem('ei', item.UF_EI_ID).UF_VALUE_PRECISION"
                        v-model="item.UF_QUANTITY"></ValidateInput>
                    <ValidateInput
                        :name="'Ед. измерения (код по ОКЕИ - Общероссийский классификатор единиц измерения)'"
                        :required="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_EI_ID.required"
                        v-model="item.UF_EI_ID">
                      <template v-slot:field>
                        <Multiselect
                            class="c-input c-select c-filter__input c-multiselect-filter"
                            v-model="item.UF_EI_ID"
                            mode="single"
                            :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_EI_ID.available"
                            :searchable="true"
                            :options="eiList"
                        />
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Планируемый срок заключения договора'"
                        :required="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.PLANNED_DOGOVOR.required"
                        v-model="item.PLANNED_DOGOVOR">
                      <template v-slot:field="{validationFailed}">
                        <div class="row row-data">
                          <div class="col-xs-12 col-sm-12 col-md-6">
                            <select
                                @change="setPlannedDogovorDate" v-model="item.pddm"
                                class="c-input c-select"
                                :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.PLANNED_DOGOVOR.available"
                                :class="{'unvalidate': validationFailed}">
                              <option value="">Месяц</option>
                              <option :value="i"
                                      :key="i" v-for="i in shared.strDigMonths()">{{ i }}
                              </option>
                            </select>
                          </div>
                          <div class="col-xs-12 col-sm-12 col-md-6">
                            <select
                                @change="setPlannedDogovorDate" v-model="item.pddy"
                                class="c-input c-select"
                                :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.PLANNED_DOGOVOR.available"
                                :class="{'unvalidate': validationFailed}">
                              <option value="">Год</option>
                              <option :value="i"
                                      :key="i" v-for="i in [
                                                      moment().toDate().getFullYear(),
                                                      moment().toDate().getFullYear()+1,
                                                      moment().toDate().getFullYear()+2
                                                      ]">{{ i }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Срок исполнения договора'"
                        :required="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.EXECUTION_DATE.required"
                        v-model="item.EXECUTION_DATE">
                      <template v-slot:field="{validationFailed}">
                        <div class="row row-data">
                          <div class="col-xs-12 col-sm-12 col-md-6">
                            <select @change="setExecutionDate" v-model="item.edm"
                                    class="c-input c-select"
                                    :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.EXECUTION_DATE.available"
                                    :class="{'unvalidate': validationFailed}">
                              <option value="">Месяц</option>
                              <option :value="i"
                                      :key="i" v-for="i in shared.strDigMonths()">{{ i }}
                              </option>
                            </select>
                          </div>
                          <div class="col-xs-12 col-sm-12 col-md-6">
                            <select @change="setExecutionDate" v-model="item.edy"
                                    class="c-input c-select"
                                    :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.EXECUTION_DATE.available"
                                    :class="{'unvalidate': validationFailed}">
                              <option value="">Год</option>
                              <option :value="i"
                                      :key="i" v-for="i in [
                                                      moment().toDate().getFullYear(),
                                                      moment().toDate().getFullYear()+1,
                                                      moment().toDate().getFullYear()+2,
                                                      moment().toDate().getFullYear()+3
                                                      ]">{{ i }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Планируемый срок размещения'"
                        :required="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.PLACEMENT_DATE.required"
                        v-model="item.PLACEMENT_DATE">
                      <template v-slot:field="{validationFailed}">
                        <div class="row row-data">
                          <div class="col-xs-12 col-sm-12 col-md-6">
                            <select
                                @change="setPlacementDate" v-model="item.pdm"
                                class="c-input c-select"
                                :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.PLACEMENT_DATE.available"
                                :class="{'unvalidate': validationFailed}">
                              <option value="">Месяц</option>
                              <option :value="i"
                                      :key="i" v-for="i in shared.strDigMonths()">{{ i }}
                              </option>
                            </select>
                          </div>
                          <div class="col-xs-12 col-sm-12 col-md-6">
                            <select
                                @change="setPlacementDate" v-model="item.pdy"
                                class="c-input c-select"
                                :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.PLACEMENT_DATE.available"
                                :class="{'unvalidate': validationFailed}">
                              <option value="">Год</option>
                              <option :value="i"
                                      :key="i" v-for="i in [
                                                      moment().toDate().getFullYear(),
                                                      moment().toDate().getFullYear()+1,
                                                      moment().toDate().getFullYear()+2
                                                      ]">{{ i }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Срок предоставления документов по закупке'"
                        :required="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_DEADLINE_DOCUMENTS.required"
                        v-model="item.EXECUTION_DATE">
                      <template v-slot:field="{}">
                        <div class="row row-data stl-data-container stl-data-container--small">
                          <VueDatePicker v-model="item.UF_DEADLINE_DOCUMENTS" format="dd.MM.yyyy"
                                         :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_DEADLINE_DOCUMENTS.available">
                          </VueDatePicker>
                        </div>
                      </template>
                    </ValidateInput>
                    <ValidateInput
                        :name="'Способ закупки'"
                        :required="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_PM_ID.required"
                        :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_PM_ID.available"
                        select
                        :items="store.state.directory.simple.purchase_plan_methods"
                        :item-key="'ID'"
                        :item-name="'UF_NAME'"
                        v-model="item.UF_PM_ID">
                    </ValidateInput>
                    <ValidateInput
                        v-if="item.BP_RIGHTS.UF_IS_CHANGE_CREATED_BY"
                        :name="'Инициатор плана закупки'"
                        required
                        select
                        :items="writers"
                        :item-key="'ID'"
                        :item-name="'FULL_NAME'"
                        v-model="item.UF_CREATED_BY">
                    </ValidateInput>
                    <div v-else class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Инициатор заявки <span
                          class="c-c-danger">*</span> </label>
                      <div>{{ item.CREATED_BY.FULL_NAME }}</div>
                    </div>
                    <ValidateInput
                        :name="'Руководитель инициатора'"
                        :required="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_CHIEF_ID.required"
                        :disabled="!item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_CHIEF_ID.available"
                        select
                        :items="chiefs"
                        :item-key="'ID'"
                        :item-name="'FULL_NAME'"
                        v-model="item.UF_CHIEF_ID">
                    </ValidateInput>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Имеет доступ на чтение</label>
                      <Multiselect
                          class="c-input c-select c-filter__input c-multiselect-filter"
                          v-model="item.USER_RIGHTS.READ"
                          mode="tags"
                          :searchable="true"
                          :create-option="true"
                          :options="readersList"
                      />
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Имеет доступ на редактирование</label>
                      <Multiselect
                          class="c-input c-select c-filter__input c-multiselect-filter"
                          v-model="item.USER_RIGHTS.EDIT"
                          mode="tags"
                          :searchable="true"
                          :create-option="true"
                          :options="writerList"
                      />
                    </div>
                  </div>
                </div>
                <div class="box-stl card-holder"
                     v-if="item.BP_RIGHTS.BLOCKS.general_information.UF_IS_VIEW && !item.BP_RIGHTS.BLOCKS.general_information.UF_IS_EDIT">
                  <div class="holder-title card-title df-jsb">
                    <div class="card-title--box df-fc">
                      Общая информация
                      <span class="holder-title__attribute" v-if="item.UF_IS_CANCELLATION">Аннулирование
                        <br/>
                      </span>
                      <span class="holder-title__attribute"
                            v-else-if="item.SOURCE && item.SOURCE.ID > 0 && item.UF_IS_EXCLUDE_PLAN === 1">Исключение закупки из плана
                    </span>
                      <span class="holder-title__attribute"
                            v-else-if="item.SOURCE && item.SOURCE.ID > 0 && item.UF_IS_EXCLUDE_PLAN === 0&& item.SOURCE.UF_STATUS_ID !== 6">Изменение закупки в плане</span>
                      <span class="holder-title__attribute"
                            v-else-if="item.SOURCE && item.SOURCE.ID > 0 && item.UF_IS_EXCLUDE_PLAN === 0&& item.SOURCE.UF_STATUS_ID === 6">
                            Внесены изменения
                      </span>
                    </div>
                    <span class="holder-title__attribute"
                          v-if="item.SOURCE && item.SOURCE.ID > 0 || item.CHILDREN.length">
                      Версия №{{ item.SOURCE_DEPTH }}<br/>
                      <router-link
                          v-if="item.CHILDREN.length"
                          :to="{name: 'CardPurchase', params: { id: item.CHILDREN[0].ID }}">Новая версия ({{
                          item.CHILDREN[0].ID
                        }}) <br/></router-link>
                       <router-link
                           v-if="item.SOURCE && item.SOURCE.ID > 0"
                           :to="{name: 'CardPurchase', params: { id: item.SOURCE.ID }}">Карточка - источник ({{
                           item.SOURCE.ID
                         }})</router-link>
                    </span>
                  </div>
                  <div class="card-holder__body">
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">План
                      </div>
                      <div class="card-holder-col">{{ item.PLAN.UF_YEAR }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Предмет закупки
                        <span class="c-c-danger"
                              v-if="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_SUBJECT.required">*</span>
                      </div>
                      <div class="card-holder-col">{{ item.UF_SUBJECT }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Цена (НМЦД - Начальная (максимальная) цена договора), руб.
                        <span class="c-c-danger"
                              v-if="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_COST.required">*</span>
                      </div>
                      <div class="card-holder-col">{{ $filters.numberFormat(item.UF_COST, 2) }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Закупка запланирована на третий или последующие годы реализации плана
                        закупки
                        <span class="c-c-danger"
                              v-if="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_IS_PLANNED_THIRD_OR_LAST.required">*</span>
                      </div>
                      <div class="card-holder-col">{{ $filters.yn(item.UF_IS_PLANNED_THIRD_OR_LAST) }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Количество
                        <span class="c-c-danger"
                              v-if="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_QUANTITY.required">*</span>
                      </div>
                      <div class="card-holder-col">{{ item.UF_QUANTITY }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Ед. измерения (код по ОКЕИ - Общероссийский классификатор единиц
                        измерения)
                        <span class="c-c-danger"
                              v-if="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_EI_ID.required">*</span>
                      </div>
                      <div class="card-holder-col">
                        {{ store.getters.simpleItem("ei", item.UF_EI_ID).UF_NAME }}, код
                        {{ store.getters.simpleItem("ei", item.UF_EI_ID).UF_CODE }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Планируемый срок заключения договора
                        <span class="c-c-danger"
                              v-if="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.PLANNED_DOGOVOR.required">*</span>
                      </div>
                      <div class="card-holder-col">{{ item.PLANNED_DOGOVOR }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Срок исполнения договора
                        <span class="c-c-danger"
                              v-if="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.EXECUTION_DATE.required">*</span>
                      </div>
                      <div class="card-holder-col">{{ item.EXECUTION_DATE }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Планируемый срок размещения
                        <span class="c-c-danger"
                              v-if="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.PLACEMENT_DATE.required">*</span>
                      </div>
                      <div class="card-holder-col">{{ item.PLACEMENT_DATE }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Срок предоставления документов по закупке
                        <span class="c-c-danger"
                              v-if="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_DEADLINE_DOCUMENTS.required">*</span>
                      </div>
                      <div class="card-holder-col" v-if="item.UF_DEADLINE_DOCUMENTS">
                        {{ moment(item.UF_DEADLINE_DOCUMENTS).format("DD.MM.YYYY") }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col card-holder-col--name">Способ закупки
                        <span class="c-c-danger"
                              v-if="item.BP_RIGHTS.BLOCKS.general_information.FIELDS.UF_PM_ID.required">*</span>
                      </div>
                      <div class="card-holder-col">
                        {{
                          store.getters.simpleItem("purchase_plan_methods", item.UF_PM_ID).UF_NAME
                        }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col card-holder-col--name">Инициатор заявки <span
                          class="c-c-danger">*</span>
                      </div>
                      <div class="card-holder-col">{{ item.CREATED_BY.FULL_NAME }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col card-holder-col--name">Руководитель инициатора <span
                          class="c-c-danger">*</span></div>
                      <div class="card-holder-col" v-if="item.CHIEF">{{ item.CHIEF.FULL_NAME }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Имеет доступ на чтение</div>
                      <div class="card-holder-col card-holder-col--confines">
                        {{ readersChecked }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Имеет доступ на редактирование</div>
                      <div class="card-holder-col">
                        {{ writersChecked }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-stl card-holder"
                     v-if="item.BP_RIGHTS.BLOCKS.status.UF_IS_VIEW">
                  <div class="holder-title card-title">Статус <span class="c-c-danger">*</span></div>
                  <div class="card-holder__body">
                    <p>{{ item.STATUS.UF_NAME }} <a @click="showStatusHistory"
                                                    class="c-i-icon c-i-time"></a></p>
                    <p v-if="item.SOURCE">
                      <router-link :to="{name: 'CardPurchase', params: { id: item.SOURCE.ID }}">Карточка - источник
                      </router-link>
                    </p>
                  </div>
                </div>
                <div class="box-stl card-holder"
                     v-if="item.BP_RIGHTS.BLOCKS.plan_by_year.UF_IS_VIEW">
                  <div class="holder-title card-title">План расходов по годам</div>
                  <div class="card-suptitle c-c-red" v-if="item.EXPENSES.length > 0 &&
                                  expensesSum !== parseFloat(item.UF_COST).toFixed(2)">Разбивка по годам не
                    соответствует
                    цене
                    закупки!
                  </div>
                  <div class="holder-responsive">
                    <table class="card-table">
                      <thead>
                      <tr>
                        <th class="stl-ws-none">Год <span class="c-c-danger">*</span></th>
                        <th>Сумма (НМЦ - Начальная цена договора), руб. <span class="c-c-danger">*</span></th>
                        <th v-if="item.BP_RIGHTS.BLOCKS.plan_by_year.UF_IS_EDIT"
                            class="card-table__th-control"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr :key="exp.ID" v-for="(exp, i) in item.EXPENSES">
                        <td>{{ exp.UF_YEAR }}</td>
                        <td>{{ $filters.numberFormat(exp.UF_SUM, 2) }}</td>
                        <td class="df-ac" v-if="item.BP_RIGHTS.BLOCKS.plan_by_year.UF_IS_EDIT">
                          <button class="c-i-icon c-i-edit stl-mr-5"
                                  @mousedown.prevent="showExpenseEdit(exp)"
                                  @click="showExpenseEdit(exp)"
                          type="button" ></button>
                          <button class="c-i-icon c-i-delete"
                                  @click="showDeleteExpenseModal(i)"></button>
                        </td>
                      </tr>
                      <tr class="card-table__tr-control"
                          v-if="item.BP_RIGHTS.BLOCKS.plan_by_year.UF_IS_EDIT">
                        <td colspan="5">
                          <button type="button"
                                  @click.prevent=""
                                  @mousedown.prevent="showExpenseEdit()"
                                  class="btn btn-add-white df-ac"><b
                              class="stl-fs-22 stl-mr-10">+</b>
                            Добавить строку
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="box-stl card-holder"
                     v-if="item.BP_RIGHTS.BLOCKS.documents.UF_IS_VIEW">
                  <div class="holder-title card-title">Документы</div>
                  <div class="card-holder__body"
                       v-if="item.BP_RIGHTS.BLOCKS.documents.UF_IS_EDIT">Объем файла не должен превышать 512 Мб
                  </div>
                  <div class="card-holder__body">
                    <div class="card-holder__document-container">
                      <div class="card-holder__document-row df-ac" :key="doc.ID"
                           v-for="doc in item.DOCUMENTS">
                        <div class="card-holder__document-name stl-mr-20">
                          <a :href="doc.URL_DOWNLOAD"
                             class="link-doc link-doc-name"
                             download> <i
                              class="d-i-icon d-i-pdf"></i>{{ doc.FILE_NAME }}</a></div>
                        <div class="df-ac" v-if="doc.CREATED_BY">
                          <span class="stl-mr-5">{{ doc.CREATED_BY.FULL_NAME }}</span>
                          <span class="stl-mr-5">{{
                              moment(doc.UF_DATE_CREATE).format("DD.MM.YYYY HH:mm")
                            }}</span>
                          <a v-if="item.BP_RIGHTS.BLOCKS.documents.UF_IS_EDIT"
                             @click="deleteDocument(doc.ID)" class="c-i-icon c-i-delete"></a>
                          <!--<a v-if="item.BP_RIGHTS.BLOCKS.documents.UF_IS_EDIT" href="#"
                             class="c-i-icon c-i-history"></a>-->
                        </div>
                      </div>
                      <!-- card-holder__document-row -->
                      <div class="card-holder__document-footer"
                           v-if="item.BP_RIGHTS.BLOCKS.documents.UF_IS_EDIT">
                        <label class="label-add-file">
                          <input type="file" class="hidden" @change="onAddFile">
                          <span class="btn btn-add-white df-ac"><b
                              class="stl-fs-22 stl-mr-10">+</b> Добавить файл</span>
                        </label>
                      </div>
                      <!-- card-holder__document-footer -->
                    </div>
                    <!-- card-holder__document-container -->
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-col">
                <div class="box-stl card-holder"
                     v-if="item.BP_RIGHTS.BLOCKS.analytics.UF_IS_VIEW && item.BP_RIGHTS.BLOCKS.analytics.UF_IS_EDIT">
                  <div class="holder-title card-title">Аналитика</div>
                  <div class="card-holder__body">
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">ОКВЭД - Общероссийский классификатор видов экономической
                        деятельности</label>
                      <Multiselect
                          class="c-input c-select c-filter__input c-multiselect-filter"
                          v-model="item.UF_OKVED_ID"
                          mode="single"
                          :loading="isLoading"
                          @search-change="okvedSearch"
                          :show-no-results="false"
                          :searchable="true"
                          :options="okvedList"
                          :no-options-text="'Введите код или название записи'"
                      />
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">ОКПД - Общероссийский классификатор продукции по видам
                        экономической деятельности</label>
                      <Multiselect
                          class="c-input c-select c-filter__input c-multiselect-filter"
                          v-model="item.UF_OKPD_ID"
                          mode="single"
                          :loading="isLoading"
                          @search-change="okpdSearch"
                          :show-no-results="false"
                          :searchable="true"
                          :options="okpdList"
                          @select="checkMSP"
                          :no-options-text="'Введите код или название записи'"
                      />
                    </div>
                    <div class="card-holder__body-row df-fc" style="display: none">
                      <label for="" class="card-holder-label">Наименование услуги <span
                          class="c-c-danger">*</span></label>
                      <textarea class="c-input c-input--c-black c-textarea c-textarea-n-resize"
                                v-model="item.UF_NAME"></textarea>
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Минимально необходимые требования, предъявляемые к
                        закупаемым товарам (работам, услугам) <span
                            class="c-c-danger">*</span></label>
                      <textarea maxlength="500" class="c-input c-input--c-black c-textarea c-textarea-n-resize"
                                v-model="item.UF_DESCRIPTION"></textarea>
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">ОКАТО - Общероссийский классификатор объектов
                        административно-территориального деления</label>
                      <select v-model="item.UF_OKATO_ID" class="c-input c-input--c-black c-select">
                        <option :value="i.ID"
                                :key="i.ID" v-for="i in store.state.directory.simple.okato">
                          {{ i.UF_NAME }}, код {{ i.UF_CODE }}
                        </option>
                      </select>
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Электронный вид (да / нет)</label>
                      <select v-model="item.UF_IS_ELECTRON" class="c-input c-input--c-black c-select">
                        <option :value="true">Да</option>
                        <option :value="false">Нет</option>
                      </select>
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Закупка товаров (работ, услуг) путем
                        проведения торгов, иных способов закупки, участниками которых являются
                        только субъекты МСП (да/нет)</label>
                      <select v-model="item.UF_IS_MSP" class="c-input c-input--c-black c-select">
                        <option :value="true">Да</option>
                        <option :value="false">Нет</option>
                      </select>
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Закупка товаров (работ, услуг),
                        удовлетворяющих критериям отнесения к инновационной продукции,
                        высокотехнологичной продукции, в том числе у субъектов МСП (да/нет)</label>
                      <select v-model="item.UF_IS_INNOVATION"
                              class="c-input c-input--c-black c-select">
                        <option :value="true">Да</option>
                        <option :value="false">Нет</option>
                      </select>
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Закупка относится к перечню закупок,
                        предусмотренных пунктом 7 "Положения об особенностях участия субъектов
                        МСП..", утв. Постановлением Правительства РФ от 11 декабря 2014 г. N 1352
                        (да/нет))</label>
                      <select v-model="item.UF_IS_P7" class="c-input c-input--c-black c-select">
                        <option :value="true">Да</option>
                        <option :value="false">Нет</option>
                      </select>
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">НМЦ - Начальная цена договора, руб. </label>
                      <input type="text" :value="expensesSum" class="c-input c-input--c-black"
                             disabled>
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Виды расходов</label>
                      <select v-model="item.UF_EXPENSE_TYPE_ID" class="c-input c-input--c-black c-select">
                        <option></option>
                        <option :value="i.ID"
                                :key="i.ID" v-for="i in store.state.directory.simple.expenses_types">
                          {{ i.UF_NAME }}
                        </option>
                      </select>
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Центры затрат</label>
                      <select v-model="item.UF_COST_CENTER_ID" class="c-input c-input--c-black c-select">
                        <option></option>
                        <option :value="i.ID"
                                :key="i.ID" v-for="i in store.state.directory.simple.cost_centers">
                          {{ i.UF_NAME }}
                        </option>
                      </select>
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <label for="" class="card-holder-label">Источник финансирования</label>
                      <select v-model="item.UF_FIN_SOURCE_ID" class="c-input c-input--c-black c-select">
                        <option></option>
                        <option :value="i.ID"
                                :key="i.ID" v-for="i in store.state.directory.simple.fin_sources">
                          {{ i.UF_NAME }}
                        </option>
                      </select>
                    </div>
                    <div class="card-holder__body-row df-fc" style="display: none">
                      <label for="" class="card-holder-label">Цена договора, руб. </label>
                      <NumberInput
                          :attrs="{class: 'c-input'}"
                          v-model="item.UF_COST"
                          :float-format="2"
                      />
                    </div>
                    <div class="card-holder__body-row df-fc" style="display: none">
                      <label for="" class="card-holder-label">Размещение</label>
                      <select v-model="item.UF_IS_DEPLOY" class="c-input c-input--c-black c-select">
                        <option :value="true">Да</option>
                        <option :value="false">Нет</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="box-stl card-holder"
                     v-if="item.BP_RIGHTS.BLOCKS.analytics.UF_IS_VIEW && !item.BP_RIGHTS.BLOCKS.analytics.UF_IS_EDIT">
                  <div class="holder-title card-title">Аналитика</div>
                  <div class="card-holder__body">
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">ОКВЭД - Общероссийский классификатор видов экономической
                        деятельности
                      </div>
                      <div class="card-holder-col" v-if="item.OKVED">{{ item.OKVED.UF_CODE }} - {{
                          item.OKVED.UF_NAME
                        }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">ОКПД - Общероссийский классификатор продукции по видам экономической
                        деятельности
                      </div>
                      <div class="card-holder-col" v-if="item.OKPD">{{ item.OKPD.UF_CODE }} - {{
                          item.OKPD.UF_NAME
                        }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb" style="display: none">
                      <div class="card-holder-col card-holder-col--name">Наименование услуги <span
                          class="c-c-danger">*</span></div>
                      <div class="card-holder-col">{{ item.UF_NAME }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col" style="min-width: 240px;">Минимально необходимые требования,
                        предъявляемые
                        к закупаемым товарам
                        (работам, услугам) <span class="c-c-danger">*</span></div>
                      <div class="card-holder-col">{{ item.UF_DESCRIPTION }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">ОКАТО - Общероссийский классификатор объектов
                        административно-территориального деления
                      </div>
                      <div class="card-holder-col">
                        {{ store.getters.simpleItem("okato", item.UF_OKATO_ID).UF_NAME }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Электронный вид (да / нет)</div>
                      <div class="card-holder-col">{{ $filters.yn(item.UF_IS_ELECTRON) }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Закупка товаров (работ, услуг) путем проведения
                        торгов, иных способов закупки, участниками которых являются только субъекты
                        МСП (да/нет)
                      </div>
                      <div class="card-holder-col">{{ $filters.yn(item.UF_IS_MSP) }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Закупка товаров (работ, услуг), удовлетворяющих
                        критериям отнесения к инновационной продукции, высокотехнологичной
                        продукции, в том числе у субъектов МСП (да/нет)
                      </div>
                      <div class="card-holder-col">{{ $filters.yn(item.UF_IS_INNOVATION) }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Закупка относится к перечню закупок,
                        предусмотренных пунктом 7 "Положения об особенностях участия субъектов
                        МСП..", утв. Постановлением Правительства РФ от 11 декабря 2014 г. N 1352
                        (да/нет)
                      </div>
                      <div class="card-holder-col">{{ $filters.yn(item.UF_IS_P7) }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">НМЦ - Начальная цена договора, руб.</div>
                      <div class="card-holder-col">{{ $filters.numberFormat(expensesSum) }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Виды расходов</div>
                      <div class="card-holder-col">
                        {{ store.getters.simpleItem("expenses_types", item.UF_EXPENSE_TYPE_ID).UF_NAME }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Центры затрат</div>
                      <div class="card-holder-col">
                        {{ store.getters.simpleItem("cost_centers", item.UF_COST_CENTER_ID).UF_NAME }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Источник финансирования</div>
                      <div class="card-holder-col">
                        {{ store.getters.simpleItem("fin_sources", item.UF_FIN_SOURCE_ID).UF_NAME }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb" style="display: none">
                      <div class="card-holder-col">Цена договора, руб.</div>
                      <div class="card-holder-col">{{ $filters.numberFormat(item.UF_COST) }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb" style="display: none">
                      <div class="card-holder-col">Размещение</div>
                      <div class="card-holder-col">{{ $filters.yn(item.UF_IS_DEPLOY) }}</div>
                    </div>
                  </div>
                </div>
                <div class="box-stl card-holder"
                     v-if="(item.UF_SOURCE_ID>0 || item.UF_IS_CANCELLATION) && item.BP_RIGHTS.UF_IS_EDIT && item.STATUS.UF_CODE !='included_in_the_purchase_plan'">
                  <div class="holder-title card-title">Причина внесения изменений <span
                      class="c-c-danger">*</span></div>
                  <div class="card-holder__body">
                    <div class="card-holder__body-row df-fc">
                      <ValidateInput
                          :name="'Причина'"
                          v-model="item.REASONS_MAKING_CHANGES_IDS">
                        <template v-slot:field>
                          <Multiselect
                              class="c-input c-select c-filter__input c-multiselect-filter c-multiselect-filter--auto-height"
                              v-model="item.REASONS_MAKING_CHANGES_IDS"
                              mode="tags"
                              :searchable="true"
                              :options="reasonList"
                          />
                        </template>
                      </ValidateInput>
                      <ValidateInput
                          :name="'Комментарий'"
                          textarea
                          v-model="item.UF_REASON_MAKING_COMMENT"></ValidateInput>
                    </div>
                  </div>
                </div>
                <div class="box-stl card-holder"
                     v-if="(item.UF_SOURCE_ID>0 || item.UF_IS_CANCELLATION) && (!item.BP_RIGHTS.UF_IS_EDIT || item.STATUS.UF_CODE =='included_in_the_purchase_plan')">
                  <div class="holder-title card-title">Причина внесения изменений <span
                      class="c-c-danger">*</span></div>
                  <div class="card-holder__body">
                    <div class="card-holder__body-row df-fc">
                      {{ makesChangedChecked }}
                    </div>
                    <div class="card-holder__body-row df-fc">
                      <div class="card-holder-col">Комментарий</div>
                      <div class="card-holder-col">
                        {{ item.UF_REASON_MAKING_COMMENT }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-stl card-holder"
                     v-if="item.BP_RIGHTS.BLOCKS.plan_fhd.UF_IS_VIEW">
                  <div class="holder-title card-title">План ФХД</div>
                  <div class="holder-responsive">
                    <table class="card-table">
                      <thead>
                      <tr>
                        <th class="stl-ws-none">Год <span class="c-c-danger">*</span></th>
                        <th>Сумма, руб. <span class="c-c-danger">*</span></th>
                        <th>Центр затрат <span class="c-c-danger">*</span></th>
                        <th>Источник финансирования <span class="c-c-danger">*</span></th>
                        <th>Вид расходов <span class="c-c-danger">*</span></th>
                        <th>КЭК <span class="c-c-danger">*</span></th>
                        <th>КВР <span class="c-c-danger">*</span></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr :key="exp.ID" v-for="(exp, i) in item.EXPENSES_FHD">
                        <td>{{ exp.UF_YEAR }}</td>
                        <td>{{ $filters.numberFormat(exp.UF_SUM, 2) }}</td>
                        <td>{{
                            store.getters.simpleItem("cost_centers", exp.UF_COST_CENTER_ID).UF_NAME
                          }}
                        </td>
                        <td>{{
                            store.getters.simpleItem("fin_sources", exp.UF_FIN_SOURCE_ID).UF_NAME
                          }}
                        </td>
                        <td>
                          {{
                            store.getters.simpleItem("expenses_types", exp.UF_EXPENSE_TYPE_ID).UF_NAME
                          }}
                        </td>
                        <td>{{ store.getters.simpleItem("cec", exp.UF_CEC_ID).UF_CODE }}</td>
                        <td>{{ store.getters.simpleItem("etc", exp.UF_ETC_ID).UF_CODE }}</td>
                        <td class="df-ac" v-if="item.BP_RIGHTS.BLOCKS.plan_fhd.UF_IS_EDIT">
                          <button class="c-i-icon c-i-edit stl-mr-5"
                                  @click="showExpenseFHDEdit(exp)"></button>
                          <button class="c-i-icon c-i-delete"
                                  @click="showDeleteFHDExpenseModal(i)"></button>
                        </td>
                      </tr>
                      <tr class="card-table__tr-control"
                          v-if="item.BP_RIGHTS.BLOCKS.plan_fhd.UF_IS_EDIT">
                        <td colspan="8">
                          <button @click="showExpenseFHDEdit()" class="btn btn-add-white df-ac"><b
                              class="stl-fs-22 stl-mr-10">+</b>
                            Добавить строку
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- page-container -->
        </div>
      </section>
      <MainFooter>
        <template v-slot:left-control>
          <div class="footer-left__controll">
            <button class="btn btn-blue" @click="saveItem(false)" v-if="item.BP_RIGHTS.UF_IS_EDIT">Сохранить
            </button>
            <template v-if="item.BP_RIGHTS.UF_IS_CHANGE_STATUS">
              <button class="btn " @click="changeStatus(i)"
                      v-for="i in changeStatuses"
                      :class="{
                                'btn-green': i.TYPE==='forward',
                                'btn-red': i.TYPE === 'backward'
                            }"
                      :key="i.NAME">{{ i.UF_TEXT_BUTTON_CHANGE }}
              </button>
            </template>
            <button class="btn btn-red" v-if="item.BP_RIGHTS.UF_IS_REVOKE"
                    @click="revokeItem(0)">Отозвать закупку
            </button>
          </div>
        </template>
        <template v-slot:default>
          <div class="footer-right__controll">
            <button class="btn btn-orange"
                    v-if="item.BP_RIGHTS.UF_IS_MAKE_CHANGE &&
                    item.BP_RIGHTS.UF_IS_MAKE_CHANGE_BT_REQUEST_SHOW &&
                    item.STATUS.UF_CODE === 'included_in_the_purchase_plan'"
                    @click="makeChange(0)">Внести изменения в план
            </button>
            <button class="btn btn-green" v-if="item.BP_RIGHTS.UF_IS_COPY && !item.UF_IS_CANCELLATION"
                    @click="copyItem(0)">Создать с копированием
            </button>
          </div>
        </template>
      </MainFooter>
    </template>
    <!-- footer -->
    <div v-if="itemLoadResult.ok === false">
      <h2 style="margin-left: 50px; margin-top: 50px;">{{ itemLoadResult.message }}</h2>
    </div>
  </div>
  <!-- Удалить запись -->
  <div class="modal" id="expense-delete" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Удалить план</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#modal-delete')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-text">
              <p>Вы действительно хотите удалить план? Данное действие невозможно будет восстановить. </p>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#expense-delete')">отменить
          </button>
          <button type="button" class="btn btn-red" @click="deleteExpense()">удалить</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="expense-fhd-delete" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Удалить план ФХД</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#modal-fhd-delete')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-text">
              <p>Вы действительно хотите удалить план? Данное действие невозможно будет восстановить. </p>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#expense-fhd-delete')">отменить
          </button>
          <button type="button" class="btn btn-red" @click="deleteFHDExpense()">удалить</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="edit-expense" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <form action="#"  v-on:submit.prevent="changeExpense" v-on:keyup.enter="changeExpense">
        <div class="modal-header">
          <h5 class="modal-title" v-if="editExpense.isNew">Добавить запись</h5>
          <h5 class="modal-title" v-if="!editExpense.isNew">Изменить запись</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#edit-expense')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
            <div class="df-fc modal-grid-row" v-if="expenseYears.length > 0">
              <label for="" class="form-label">Год<span
                  class="c-c-danger">*</span></label>
              <select v-model="editExpense.UF_YEAR" class="c-input c-select"
                      :disabled="!editExpense.isNew"
              >
                <option :value="i" :key="i"
                        v-for="i in expenseYears">
                  {{ i }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row" v-if="expenseYears.length > 0">
              <label for="" class="form-label">Сумма<span
                  class="c-c-danger">*</span></label>
              <NumberInput
                  :attrs="{class: 'c-input'}"
                  v-model="editExpense.UF_SUM"
                  :float-format="2"
              />
            </div>
            <div class="df-fc modal-grid-row" v-else>
              В плане расходов по годам уже есть данные по всем доступным годам.
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#edit-expense')">отменить
          </button>
          <button type="button" v-if="!editExpense.isNew" class="btn btn-blue" @click="changeExpense">
            изменить
          </button>
          <button type="button" v-if="editExpense.isNew" class="btn btn-blue" @click="changeExpense">
            добавить
          </button>
        </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal" id="edit-expense-fhd" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="editExpenseFHD.isNew">Добавить запись</h5>
          <h5 class="modal-title" v-if="!editExpenseFHD.isNew">Изменить запись</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#edit-expense-fhd')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Год<span
                  class="c-c-danger">*</span></label>
              <select v-model="editExpenseFHD.UF_PLAN_EXPENSE_ID" class="c-input c-select">
                <option :value="i.ID" :key="i.ID"
                        v-for="i in item.EXPENSES">
                  {{ i.UF_YEAR }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Сумма<span
                  class="c-c-danger">*</span></label>
              <NumberInput
                  :attrs="{class: 'c-input'}"
                  v-model="editExpenseFHD.UF_SUM"
                  :float-format="2"
              />
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Центр затрат<span class="c-c-danger">*</span></label>
              <select v-model="editExpenseFHD.UF_COST_CENTER_ID" class="c-input c-select">
                <option :value="i.ID" :key="i.ID"
                        v-for="i in store.state.directory.simple.cost_centers">
                  {{ i.UF_NAME }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Источник финансирования<span
                  class="c-c-danger">*</span></label>
              <select v-model="editExpenseFHD.UF_FIN_SOURCE_ID" class="c-input c-select">
                <option :value="i.ID" :key="i.ID"
                        v-for="i in store.state.directory.simple.fin_sources">
                  {{ i.UF_NAME }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Вид расходов<span class="c-c-danger">*</span></label>
              <select v-model="editExpenseFHD.UF_EXPENSE_TYPE_ID" class="c-input c-select">
                <option :value="i.ID" :key="i.ID"
                        v-for="i in store.state.directory.simple.expenses_types">
                  {{ i.UF_NAME }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">КЭК<span class="c-c-danger">*</span></label>
              <select v-model="editExpenseFHD.UF_CEC_ID" class="c-input c-select">
                <option :value="i.ID" :key="i.ID"
                        v-for="i in store.state.directory.simple.cec">
                  {{ i.UF_CODE }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">КВР<span class="c-c-danger">*</span></label>
              <select v-model="editExpenseFHD.UF_ETC_ID" class="c-input c-select">
                <option :value="i.ID" :key="i.ID"
                        v-for="i in store.state.directory.simple.etc">
                  {{ i.UF_CODE }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#edit-expense-fhd')">отменить
          </button>
          <button type="button" v-if="!editExpenseFHD.isNew" class="btn btn-blue" @click="changeExpenseFHD">
            изменить
          </button>
          <button type="button" v-if="editExpenseFHD.isNew" class="btn btn-blue" @click="changeExpenseFHD">
            добавить
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="edit-status" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Добавление комментария при возврате на доработку</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#edit-status')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">{{ editStatus.UF_TEXT_LABEL_CONTROL }}<span
                  class="c-c-danger">*</span></label>
              <textarea type="text" class="c-input c-textarea c-textarea-n-resize"
                        v-model="editStatus.COMMENT"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#edit-status')">отменить
          </button>
          <button type="button" class="btn btn-blue" @click="saveStatus(editStatus)">
            вернуть
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="status-history" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">История изменений статуса карточки закупки</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#status-history')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <table class="card-table">
            <thead>
            <tr>
              <th>ФИО</th>
              <th>Статус</th>
              <th>Дата начала действия</th>
              <th>Дата окончания действия</th>
            </tr>
            </thead>
            <tbody>
            <tr :key="i.ID" v-for="i in statusHistory">
              <td>{{ i.CREATED_BY.FULL_NAME }}</td>
              <td>{{ i.STATUS.UF_NAME }}
                <span v-if="i.UF_COMMENT"><br/>Комментарий:<br/>{{ i.UF_COMMENT }}</span>
              </td>
              <td>{{ moment(i.UF_ACTIVE_FROM).format("DD.MM.YYYY HH:mm") }}</td>
              <td v-if="i.UF_ACTIVE_TO">{{ moment(i.UF_ACTIVE_TO).format("DD.MM.YYYY HH:mm") }}</td>
              <td v-else></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="revoke-item" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Добавление комментария при отзыве закупки</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#revoke-item')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Причина отзыва<span
                  class="c-c-danger">*</span></label>
              <textarea type="text" class="c-input c-textarea c-textarea-n-resize"
                        v-model="revoke.COMMENT"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#revoke-item')">отменить
          </button>
          <button type="button" class="btn btn-blue" @click="revokeItem(1)">
            Отозвать закупку
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="copy-item" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Создать с копированием</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#copy-item')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          Вы действительно хотите создать новую заявку с копированием?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-red" @click="shared.closeModal('#copy-item')">Нет
          </button>
          <button type="button" class="btn btn-green" @click="copyItem(1)">
            Да
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="make-change-item" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Внести изменения в план</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#make-change-item')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          Вы действительно хотите внести изменения в план?
        </div>
        <div class="modal-footer df-jc">
          <button type="button" class="btn btn-green" @click="makeChange(1)">
            Внести изменения
          </button>
          <button type="button" class="btn btn-orange" @click="excludePlan(1)">
            Исключить закупку из плана
          </button>
          <button class="btn btn-blue"
                  @click="cancelPlan(1)">Аннулировать
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
